<template>
  <div class="upload-pic">
    <a-spin :spinning="loading">
      <div class="pic-title">
        <span>上传图片至图片库</span>
      </div>
      <div class="uploader">
        <a-upload-dragger
          name="file"
          :multiple="true"
          accept=".jpg, .png, .jpeg"
          :file-list="file_list"
          :customRequest="customRequest"
          :beforeUpload="beforeUploadImg"
          @change="handleChange"
          :disabled="isUploading"
        >
          <p class="ant-upload-drag-icon">
            <a-icon type="inbox" />
          </p>
          <p class="ant-upload-text">点击或将文件拖拽到这里上传</p>
          <p class="ant-upload-hint">支持扩展名：.png .jpg .jpeg</p>
          <p class="ant-upload-hint">单次上传最大50张</p>
        </a-upload-dragger>
      </div>
      <div style="margin: 0 30px; color: #a8a8a8">
        共 {{ this.file_list.filter((v) => v.status === 'done').length || 0 }} 张图片
      </div>
      <a-divider></a-divider>
      <a-row style="margin: 10px" type="flex" justify="space-around" align="middle">
        <a-col :span="7" style="display: flex; align-items: center">
          <div style="width: 55px"><span style="color: red">*</span>品牌：</div>
          <a-select
            v-model="form.principalData"
            placeholder="请选择品牌"
            show-search
            option-filter-prop="children"
            style="flex: 1"
            @change="handlePrincipalChange"
          >
            <a-select-option
              v-for="item in principalList"
              :key="item.principalId"
              :value="`${item.principalId},${item.principalName}`"
              >{{ item.principalName }}</a-select-option
            >
          </a-select>
        </a-col>
        <a-col :span="7" style="display: flex; align-items: center">
          <div style="width: 65px">文件夹：</div>
          <PicFolderTree
            ref="PicFolderTree"
            style="flex: 1"
            :treeDefaultExpandAll="true"
            v-model="form.folderId"
            @input="
              (folderId) => {
                form.folderId = folderId;
              }
            "
          />
        </a-col>
        <a-col :span="7" style="display: flex; align-items: center">
          <div style="width: 65px">车系：</div>
          <a-select v-model="form.seriesData" placeholder="请选择车系" option-filter-prop="children" style="flex: 1">
            <a-select-option v-for="item in seriesList" :key="`${item.id},${item.name}`">{{
              item.name
            }}</a-select-option>
          </a-select>
        </a-col>
      </a-row>
      <div style="margin-top: 45px; display: flex; align-items: center; justify-content: center">
        <a-button type="primary" :loading="isUploading" @click="handleUploadConfirm">确认上传</a-button>
        <a-button style="margin-left: 20px" @click="handleBack">返回</a-button>
      </div>
    </a-spin>
  </div>
</template>

<script>
import SparkMD5 from 'spark-md5';
import ossUploadMixin from '@/components/BaseUpload/ossUploadMixin.js';
import PicFolderTree from '@/components/xhsAgencyTool/PicFolderTree.vue';
import api from '../api';

export default {
  name: 'uploadPic',
  mixins: [ossUploadMixin],
  components: { PicFolderTree },
  props: {
    principalList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      seriesList: [],
      file_list: [],
      form: {
        principalData: undefined,
        seriesData: undefined,
        folderId: undefined,
      },
      loading: false,
    };
  },
  computed: {
    isUploading() {
      let hasLoading = false;
      this.file_list.forEach((v) => {
        if (v.status === 'uploading') {
          hasLoading = true;
        }
      });
      return hasLoading;
    },
  },
  methods: {
    async init(initData) {
      const { principalId, folderId } = initData;
      const principalData = this.principalList.find((v) => v.principalId === principalId);
      if (principalData) {
        this.form.principalData = `${principalData.principalId},${principalData.principalName}`;
        await this.$refs.PicFolderTree.getFolderList(this.form.principalData.split(',')[0]);
        folderId && (this.form.folderId = folderId);
        this.getSeriesList();
      }
    },
    handlePrincipalChange() {
      this.form.folderId = undefined;
      this.form.seriesData = undefined;
      this.seriesList = [];
      this.$refs.PicFolderTree.getFolderList(this.form.principalData.split(',')[0]);
      this.getSeriesList();
    },
    getSeriesList() {
      if (this.form.principalData) {
        this.handleRequest(
          'getNewTypeList',
          (data) => {
            this.seriesList = data;
          },
          { principalIds: this.form.principalData.split(',')[0] }
        );
      }
    },
    handleUploadConfirm() {
      if (!this.file_list || !this.file_list.length) return this.$message.error('请先上传图片');
      if (!this.form.principalData) return this.$message.error('请先选择品牌');
      let fileUrlList = [];
      let folderId = this.form.folderId?.length && this.form.folderId != -1 ? this.form.folderId : undefined;
      let principalId = this.form.principalData.split(',')[0];
      let principalName = this.form.principalData.split(',')[1];

      this.file_list.forEach((v) => {
        if (v.status === 'done') {
          fileUrlList.push(v.url);
        }
      });
      const params = {
        fileUrlList,
        folderId,
        principalId,
        principalName,
      };
      if (this.form.seriesData) {
        params.vehicleSeriesInfoList = [
          {
            vehicleSeriesId: this.form.seriesData.split(',')[0],
            vehicleSeriesName: this.form.seriesData.split(',')[1],
          },
        ];
      }
      this.loading = true;
      this.handleRequest(
        'uploadPictureToStore',
        () => {
          this.$message.success('上传成功');
          this.file_list = [];
        },
        params
      ).finally(() => (this.loading = false));
    },
    async customRequest(option) {
      const { file, onSuccess } = option;
      await this.normalUploadFile(file, onSuccess, true);
    },
    handleChange(info) {
      if (info.file.status) {
        this.file_list = info.fileList;

        if (info.file.status === 'uploading') {
          this.file_list.forEach((val) => {
            if (val.fileId === info.file.fileId) {
              val.status = 'uploading';
            }
          });
        }

        if (info.file.status === 'done') {
          let fileList = [...info.fileList];
          fileList = fileList.map((file) => {
            if (file.response) {
              file.fileId = file.response.data.data.id;
              file.url = file.response.data.data.fileUrl;
              file.status = 'done';
            }
            return file;
          });
          this.file_list = fileList;
        }

        if (info.file.status === 'error') {
          this.$message.error(`${info.file.name} 文件上传失败`);
        }
      } else {
        return;
      }
    },
    beforeUploadImg(file, fileList) {
      const index = fileList.findIndex((ele) => ele.uid == file.uid);
      if (fileList.length > 50 && index >= 50) return false;
      return new Promise((resolve, reject) => {
        const isLimit = file.size / 1024 / 1024 < 20;
        if (!isLimit) {
          this.$message.error(`上传文件不超过20MB!`);
          return false;
        }
        let fileType = /\.(jpeg|jpg|png)$/i;
        if (!fileType.test(file.name)) {
          this.$message.error('仅支持图片文件');
          return false;
        }
        let fileReader = new FileReader();
        let spark = new SparkMD5.ArrayBuffer(); //获取文件二进制数据
        fileReader.readAsArrayBuffer(file); //file就是获取到的文件 //异步执行函数
        fileReader.onload = function (e) {
          spark.append(e.target.result);
          let md5 = spark.end();
          file.md5 = md5;
          file.params_data = {
            appId: 'bfz',
            filePath: '/bfz/xhs/',
            identifier: file.md5,
            name: file.name,
            title: file.name.split('.')[0],
            source: 'XHS_PIC_LIBRARY',
          };
          return resolve(true);
        };
      });
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      return new Promise(async (resolve) => {
        const { code, data, message } = await api[APINAME](PARAMS);
        if (code === 200) {
          callbackFn(data);
          resolve();
        } else {
          this.$message.error(`${message}`);
          resolve();
        }
      });
    },
    handleBack() {
      this.$emit('back');
      this.file_list = [];
      this.seriesList = [];
      this.$refs.PicFolderTree.clearList();
      this.form = {
        principalData: undefined,
        seriesData: undefined,
        folderId: undefined,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.upload-pic {
  width: 100%;
  height: 100%;
  min-height: 500px;
  padding: 10px;
  background-color: #fff;
}
.pic-title {
  margin: 10px;
  font-weight: bolder;
  font-size: 18px;
}

.uploader {
  margin: 60px 150px 15px;
}

/deep/ .ant-upload-list {
  max-height: 245px;
  overflow-y: auto;
}
</style>
