<template>
  <div class="picture-card">
    <div class="tag-box">
      <!-- 标签 -->
      <div class="card-img-tag">已下载 {{ picData.downloadCount || 0 }} 次</div>
      <div class="card-img-tag" style="top: 30px">已编辑 {{ picData.editorCount || 0 }} 次</div>
    </div>
    <!-- 选择框 -->
    <div class="checkbox">
      <a-checkbox v-show="checkable" :checked="boxChecked" @change="handleCheckImg"></a-checkbox>
    </div>
    <!-- 图片 -->
    <div class="img-wrap" :style="{ 'background-image': `url(${picData.previewUrl || picData.fileUrl })` }" @click="handlePicPreview"></div>
    <div class="btn-line">
      <a-tooltip title="下载">
        <a-icon type="download" @click="handleDownload" />
      </a-tooltip>
      <a-divider type="vertical" />
      <a-tooltip title="编辑">
        <a-icon type="edit" @click="jumpEditImage" />
      </a-tooltip>
      <a-divider type="vertical" />
      <a-tooltip title="存入我的图库">
        <a-icon type="vertical-align-top" @click="singleToMyPicStore" />
      </a-tooltip>
      <a-divider type="vertical" />
      <a-tooltip title="删除" placement="topRight">
        <a-popconfirm title="确定删除该图片吗?" @confirm="handleDeletePicture">
          <a-icon type="delete" @click="() => {}" />
        </a-popconfirm>
      </a-tooltip>
    </div>
  </div>
</template>

<script>
import api from '../api';
export default {
  name: 'PictureCardComp',
  props: {
    imgCheckIdList: {
      default: () => [],
    },
    picData: {
      type: Object,
      default: () => {},
    },
    checkable: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {
    boxChecked() {
      if (this.imgCheckIdList.length && this.imgCheckIdList.filter((v) => v === this.picData.id).length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handlePicPreview() {
      this.$emit('preview', [this.picData.fileUrl]);
    },
    handleCheckImg(e) {
      const checkList = this.imgCheckIdList;
      if (e.target.checked) {
        checkList.push(this.picData.id);
      } else {
        let index = checkList.findIndex((item) => item === this.picData.id);
        checkList.splice(index, 1);
      }
      this.$emit('checkImg', checkList);
    },
    handleDownload() {
      if (!this.picData.fileUrl) return this.$message.error('图片链接为空');
      this.handleSaveCount();
      const imgUrl = `${this.picData.fileUrl}?time=${Date.now()}`;
      const title = this.picData.fileName || '图片';
      const hide = this.$message.loading('正在获取图片...', 0);
      fetch(imgUrl)
        .then(async (res) => {
          res
            .blob()
            .then((blob) => {
              const a = document.createElement('a');
              a.style.display = 'none';
              a.href = URL.createObjectURL(blob);
              a.download = title;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            })
            .catch(() => {
              // 获取图片失败
              window.open(this.picData.fileUrl, '_blank');
            });
        })
        .catch(() => {
          // 获取图片失败
          window.open(this.picData.fileUrl, '_blank');
        })
        .finally(() => hide());
    },
    handleSaveCount() {
      api.recordDownloadCount({ ids: [this.picData.id] }).then(({ code }) => {
        if (code === 200) {
          this.$emit('refresh');
        }
      });
    },
    jumpEditImage() {
      this.$emit('handleEditPic', this.picData);
    },
    handleDeletePicture() {
      this.$emit('handleDelSinglePic', this.picData.id);
    },
    singleToMyPicStore() {
      this.$emit('singleToMyPicStore', this.picData);
    },
  },
};
</script>

<style lang="less" scoped>
.picture-card {
  position: relative;
  border: 1px solid #e8e8e8;
}

.tag-box {
  position: absolute;
  left: 3px;
  top: 3px;
  z-index: 2;
  border-radius: 0;
  &:hover {
    opacity: 1;
  }
}

.card-img-tag {
  margin-bottom: 6px;
  padding: 0 7px;
  font-size: 13px;
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
  line-height: 20px;
  white-space: nowrap;
  border-radius: 3px;
  z-index: 2;
}

.img-wrap {
  position: relative;
  width: 100%;
  height: 20vh;
  min-height: 200px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
  background-size: contain;
  background-position: center center;
  background-color: #000;
}

.btn-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid rgb(233, 233, 233);
  padding: 10px 15px;
  background: #f2f4f5;
}

.checkbox {
  position: absolute;
  top: 3px;
  right: 3px;
  line-height: 0;
  z-index: 2;
  /deep/ .ant-checkbox-inner {
    width: 30px;
    height: 30px;
  }
  /deep/ .ant-checkbox-inner::after {
    width: 10px;
    height: 17px;
  }
}
</style>
