<template>
  <a-modal
    :title="title"
    v-model="visible"
    width="380px"
    :confirmLoading="saveLoading"
    @ok="save"
    @cancel="onClose"
    :maskClosable="false"
  >
    <div style="display: flex; align-items: center">
      目录名称：
      <a-input
        v-model.trim="form.fileName"
        placeholder="目录名称"
        style="width: 70%"
        :maxLength="15"
        @pressEnter="save"
      />
    </div>
  </a-modal>
</template>

<script>
import api from '../api';

export default {
  components: {},
  data() {
    return {
      visible: false,
      saveLoading: false,
      title: '',
      form: {},
    };
  },
  methods: {
    openModal({ title, form }) {
      Object.assign(this.$data, this.$options.data());
      this.title = title;
      this.form = form;
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    updateColor(value) {
      this.form.colorHex = value.hex8;
    },
    async save() {
      if (!this.form.fileName) {
        this.$message.warning('目录名称不能为空!');
        return;
      }
      this.saveLoading = true;
      const APINAME = this.title === '重命名' ? 'renamePictureFolder' : 'addPictureFolder';
      const { code, message } = await api[APINAME](this.form).finally(() => {
        this.saveLoading = false;
      });
      if (code === 200) {
        this.$message.success('操作成功');
        this.$emit('success');
        this.visible = false;
      } else {
        this.$message.error(`${message}`);
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
